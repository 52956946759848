<div class="container">
  <div class="pb-4">
    <img src="assets/FGP_principal_cores_alta.jpg" height="100" class="my-4 pr-4" />
    <img src="assets/LOGO WGfLC 2025_22-26_V.png" height="100" class="my-4" />
  </div>

  <div class="row">
    <div class="col-sm-6 mb-4">
      <h4>Contacts:</h4>
      <div>LOC - GYMNASTICS FEDERATION OF PORTUGAL</div>
      <div>Estrada da Luz, Nº 30 A</div>
      <div>1600-159 Lisboa, Portugal</div>

      <h6 class="pt-4">Contact Person:</h6>
      <div>Mrs. Paula Araújo</div>
      <div><a href="mailto:info.gymforlife2025@ginastica.org">info.gymforlife2025@ginastica.org</a></div>
      <div>Tel. <a href="tel:+351218141145">+351 21 814 11 45</a></div>
      <div><a href="https://www.ginastica.org/wgflc2025" target="_blank">www.ginastica.org/wgflc2025</a></div>
      <br />
      <br />
      <div>FIG - FÉDÉRATION INTERNATIONALE DE GYMNASTIQUE</div>
      <div>Avenue de la Gare 12a</div>
      <div>1003 Lausanne, Switzerland</div>
      <h6 class="pt-4">Contact Person:</h6>
      <div>Mr. Alexandre Cola</div>
      <div><a href="mailto:acola@fig-gymnastics.org">acola@fig-gymnastics.org</a></div>
      <div>Tel. <a href="tel:+41213215518">+41 21 321 55 18</a></div>
      <div><a href="https://www.gymnastics.sport/site/" target="_blank">www.gymnastics.sport/site/</a></div>
    </div>
    <div class="col-sm-6">
      <h4>Downloads:</h4>
      <div class="pb-2">
        <a href="assets/docs/GfA_2025_WGfLC_Lisbon_Newsletter_1_federation.pdf" target="_blank"
          >WGFLC'25 NEWSLETTER #1 - MARCH - 2023</a
        >
      </div>
      <div class="pb-2">
        <a href="assets/docs/GfA_2025_WGfLC_Lisbon_Newsletter_2_federation-e_F.pdf" target="_blank"
          >WGFLC'25 NEWSLETTER #2 - JANUARY - 2024</a
        >
      </div>
      <div class="pb-2">
        <a href="assets/docs/GfA_2025_WGfLC_Lisbon_Newsletter_3_federations-e_ABR 2024.pdf" target="_blank"
          >WGFLC'25 NEWSLETTER #3 - APRIL - 2024</a
        >
      </div>

      <div class="pb-2">
        <a href="assets/docs/GfA_2025_WGfLC_Lisbon_Newsletter_3.1_federations-e_AUG 2024.pdf" target="_blank"
          >WGFLC'25 NEWSLETTER #3.1 - AUGUST - 2024</a
        >
      </div>

      <div class="pb-2">
        <a href="assets/docs/GfA_2025_WGfLC_Lisbon_Newsletter_4_federations-e_NOV 2024.pdf" target="_blank"
          >WGFLC'25 NEWSLETTER #4 - NOVEMBER - 2024</a
        >
      </div>

      <div class="pb-2">
        <a href="assets/docs/WGFLC25_def_registration.xlsx" target="_blank">WGFLC'25 Registration File</a>
      </div>
    </div>
  </div>
</div>
