<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Add Gymnast</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form (ngSubmit)="save()" [formGroup]="form" novalidate>
    <input type="hidden" formControlName="group_id" />
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      {{ error }}
    </div>
    <div class="form-group">
      <label class="d-block">Family name *</label>
      <input type="text" class="form-control" formControlName="family_name" autocomplete="family_name" />
      <small
        [hidden]="form.controls.family_name.valid || form.controls.family_name.untouched"
        class="text-danger"
        translate
      >
        Family name is required
      </small>
    </div>
    <div class="form-group">
      <label class="d-block">First name *</label>
      <input type="text" class="form-control" formControlName="first_name" autocomplete="first_name" />
      <small
        [hidden]="form.controls.first_name.valid || form.controls.first_name.untouched"
        class="text-danger"
        translate
      >
        First name is required
      </small>
    </div>
    <div class="form-group">
      <label class="d-block">Date of birth *</label>
      <div class="input-group">
        <input
          [minDate]="{ year: 1940, month: 1, day: 1 }"
          [maxDate]="{ year: 2024, month: 12, day: 31 }"
          class="form-control"
          placeholder="dd/mm/yyyy"
          formControlName="date_of_birth"
          ngbDatepicker
          #d="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
      <small
        [hidden]="form.controls.date_of_birth.valid || form.controls.date_of_birth.untouched"
        class="text-danger"
        translate
      >
        Date of birth is required
      </small>
    </div>
    <div class="form-group">
      <label class="d-block">Gender *</label>
      <select class="form-control" formControlName="gender" autocomplete="gender">
        <option value=""></option>
        <option value="M">M</option>
        <option value="F">F</option>
        <option value="NON_BINARY">NON_BINARY</option>
      </select>
      <small [hidden]="form.controls.gender.valid || form.controls.gender.untouched" class="text-danger" translate>
        Gender is required
      </small>
    </div>
    <div class="form-group">
      <label class="d-block">Passport *</label>
      <input type="text" class="form-control" formControlName="passport" autocomplete="passport" />
      <small [hidden]="form.controls.passport.valid || form.controls.passport.untouched" class="text-danger" translate>
        Passport is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Nationality *</label>
      <input type="text" class="form-control" formControlName="nationality" autocomplete="nationality" />
      <small
        [hidden]="form.controls.nationality.valid || form.controls.nationality.untouched"
        class="text-danger"
        translate
      >
        Nationality is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Wheelchair User *</label>
      <select class="form-control" formControlName="wheelchair_user" autocomplete="wheelchair_user">
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>
      <small
        [hidden]="form.controls.wheelchair_user.valid || form.controls.wheelchair_user.untouched"
        class="text-danger"
        translate
      >
        Wheelchair User is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Group position *</label>
      <select class="form-control" formControlName="group_position" autocomplete="group_position">
        <option value=""></option>
        <option *ngFor="let item of config.group_position | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small
        [hidden]="form.controls.group_position.valid || form.controls.group_position.untouched"
        class="text-danger"
        translate
      >
        Group position is required
      </small>
    </div>

    <div><strong class="card-title">Acommodation</strong></div>
    <hr />

    <div class="form-group">
      <label class="d-block">Acommodation type *</label>
      <select class="form-control" formControlName="acommodation_type" autocomplete="acommodation_type">
        <option *ngFor="let item of config.acommodation_type | keyvalue" value="{{ item.key }}">{{
          item.value
        }}</option>
      </select>
      <small
        [hidden]="form.controls.acommodation_type.valid || form.controls.acommodation_type.untouched"
        class="text-danger"
        translate
      >
        Acommodation type is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Acommodation type room</label>
      <select class="form-control" formControlName="acommodation_type_room" autocomplete="acommodation_type_room">
        <option *ngFor="let item of config.acommodation_type_room | keyvalue" value="{{ item.key }}">{{
          item.value
        }}</option>
      </select>
    </div>

    <div class="form-group">
      <label class="d-block">Sleeping items</label>
      <select class="form-control" formControlName="sleeping_items" autocomplete="sleeping_items">
        <option *ngFor="let item of config.sleeping_items | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small
        [hidden]="form.controls.sleeping_items.valid || form.controls.sleeping_items.untouched"
        class="text-danger"
        translate
      >
        Sleeping items is required
      </small>
    </div>

    <div><strong class="card-title">Catering / Meals (Extra)</strong></div>
    <hr />

    <div class="form-group">
      <label class="d-block">Pick a pack</label>
      <select class="form-control" formControlName="meals_1" autocomplete="meals_1">
        <option *ngFor="let item of config.meal | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small [hidden]="form.controls.meals_1.valid || form.controls.meals_1.untouched" class="text-danger" translate>
        Pack is required
      </small>
    </div>

    <!-- <div class="form-group">
      <label class="d-block">Day 14-07</label>
      <select class="form-control" formControlName="meals_2" autocomplete="meals_2">
        <option value=""></option>
        <option *ngFor="let item of config.meal | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small [hidden]="form.controls.meals_2.valid || form.controls.meals_2.untouched" class="text-danger" translate>
        Meal is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Day 15-07</label>
      <select class="form-control" formControlName="meals_3" autocomplete="meals_3">
        <option *ngFor="let item of config.meal | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small [hidden]="form.controls.meals_3.valid || form.controls.meals_3.untouched" class="text-danger" translate>
        Meal is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Day 16-07</label>
      <select class="form-control" formControlName="meals_4" autocomplete="meals_4">
        <option *ngFor="let item of config.meal | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small [hidden]="form.controls.meals_4.valid || form.controls.meals_4.untouched" class="text-danger" translate>
        Meal is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Day 17-07</label>
      <select class="form-control" formControlName="meals_5" autocomplete="meals_5">
        <option *ngFor="let item of config.meal | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small [hidden]="form.controls.meals_5.valid || form.controls.meals_5.untouched" class="text-danger" translate>
        Meal is required
      </small>
    </div> -->

    <div><strong class="card-title">Insurance</strong></div>
    <hr />

    <div class="form-group">
      <label class="d-block">Insurance Type *</label>
      <select class="form-control" formControlName="insurance_type" autocomplete="insurance_type">
        <option *ngFor="let item of config.insurance_type | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
      </select>
      <small
        [hidden]="form.controls.insurance_type.valid || form.controls.insurance_type.untouched"
        class="text-danger"
        translate
      >
        Insurance type is required
      </small>
    </div>

    <div class="form-group">
      <label class="d-block">Policy number *</label>
      <input
        type="text"
        class="form-control"
        formControlName="insurance_policy_number"
        autocomplete="insurance_policy_number"
      />
      <small
        [hidden]="form.controls.insurance_policy_number.valid || form.controls.insurance_policy_number.untouched"
        class="text-danger"
        translate
      >
        Policy number is required
      </small>
    </div>

    <div><strong class="card-title">Travelling (Arrival)</strong></div>
    <hr />

    <div class="form-group">
      <label class="d-block">Arrival + transfer order</label>
      <select
        class="form-control"
        formControlName="traveling_arrival_transf_order"
        autocomplete="traveling_arrival_transf_order"
      >
        <option *ngFor="let item of config.traveling_transfer_order | keyvalue" value="{{ item.key }}">{{
          item.value
        }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="d-block">Date of arrival</label>
      <div class="input-group">
        <input
          class="form-control"
          placeholder="dd/mm/yyyy"
          formControlName="traveling_date_arrival"
          ngbDatepicker
          #d2="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="d-block">Time of arrival</label>
      <ngb-timepicker formControlName="traveling_time_arrival"></ngb-timepicker>
    </div>
    <div class="form-group">
      <label class="d-block">Flight number</label>
      <input
        type="text"
        class="form-control"
        formControlName="traveling_flight_number"
        autocomplete="traveling_flight_number"
      />
    </div>

    <div><strong class="card-title">Travelling (Departure)</strong></div>
    <hr />

    <div class="form-group">
      <label class="d-block">Departure + transfer order</label>
      <select
        class="form-control"
        formControlName="traveling_departure_transfer_order"
        autocomplete="traveling_departure_transfer_order"
      >
        <option *ngFor="let item of config.traveling_transfer_order | keyvalue" value="{{ item.key }}">{{
          item.value
        }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="d-block">Date of departure</label>
      <div class="input-group">
        <input
          class="form-control"
          placeholder="dd/mm/yyyy"
          formControlName="traveling_date_departure"
          ngbDatepicker
          #d3="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="d3.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="d-block">Time of departure</label>
      <ngb-timepicker formControlName="traveling_time_departure"></ngb-timepicker>
    </div>
    <div class="form-group">
      <label class="d-block">Flight number</label>
      <input
        type="text"
        class="form-control"
        formControlName="traveling_departure_flight_number"
        autocomplete="traveling_departure_flight_number"
      />
    </div>

    <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid || isLoading">
      <span translate>Save</span>
    </button>
  </form>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
